import axios from 'axios';

const url = 'https://obscure-everglades-53280.herokuapp.com/api';

export const fetchData = async () => {
  try {
    const response = await axios.get(url);

    return response;
  } catch (error) {}
};
