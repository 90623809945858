
import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Container,
    List,
    ListItem,
    Link
} from '@material-ui/core';
import { fetchData } from '../../api';
import styles from './Cards.module.css'




class Cards extends React.Component {
    state = {
        data: null,
    }


    async componentDidMount() {
        const data = await fetchData();
        this.setState({ data: data.data })

    }

    render() {



        const flexContainer = {
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
        };

        if (!this.state.data) return null
        return (
            <div>
                <Container fixed>

                    <Typography className={styles.headertext}>COVID 38 Tracker</Typography>

                    <Container>
                        <Grid container>
                            <Grid item className='pt-5'>
                                <Typography className={styles.subtext}>
                                    <Link target="_blank" rel=" noopener noreferrer" color="inherit" className='MuiLink-underlineAlways' href="https://www.ndr.de/nachrichten/niedersachsen/braunschweig_harz_goettingen/Corona-Braunschweiger-Forscher-finden-Antikoerper,helmholtz110.html" >
                                        Braunschweiger Forscher finden Antikörper
                                    </Link>
                                </Typography>
                                <Typography className={styles.subtext}>
                                    Ab dem 27.April ist in ganz Niedersachsen das Tragen einer Maske im Nahverkehr und im Einzelhandel Pflicht.
                                 </Typography>
                            </Grid>
                        </Grid>
                    </Container>

                    <Grid container spacing={12} justify='center' >
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>

                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[0].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[0].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[0].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[0].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[1].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[1].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[1].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[1].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[2].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[2].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[2].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[2].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[3].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[3].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[3].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[3].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[4].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[4].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[4].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[4].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[5].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[5].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[5].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[5].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[6].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[6].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[6].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[6].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                        <Grid item component={Card} xs={12} md={5} xl={4} className={styles.margin}>
                            <CardContent justify='center'>
                                <Typography className={styles.stadt}>{this.state.data[7].stadt}</Typography>
                                <List style={flexContainer}>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderblue} >{this.state.data[7].inf}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.bordergreen} >{this.state.data[7].ges}</h3>
                                    </ListItem>

                                    <ListItem className={styles.jus}>
                                        <h3 className={styles.borderred} >{this.state.data[7].tot}</h3>
                                    </ListItem>

                                </List>
                                <List style={flexContainer}>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Inzidenzwert</Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Infektionen </Typography>
                                    </ListItem>
                                    <ListItem className={styles.jus}>
                                        <Typography className={styles.text} variant='overline'>Gestorben</Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Grid>
                    </Grid>
                    <Container justify='center'>
                        <Grid container spacing={12} justify='center' direction="column"
                            alignItems="center" >
                            <h1 className={styles.time}>Stand: {this.state.data[8].date}</h1>
                            <p className={styles.white}> Erster Wert 7-Tagesinzidenz. Seit Beginn insgesamt gemeldete Infektionen in Grün. Verstorbene in Rot. Über Genesene gibt es kaum noch offz. Angaben, daher keine Ausweisung mehr. Quelle: RKI, Angaben der Landkreise, Städte oder des NLGA.</p>
                        </Grid>
                    </Container>
                </Container>
            </div >
        )
    }
}


export default Cards
