import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'


import {
    Typography,
    Grid,
    Container,
    Link
} from '@material-ui/core';

import styles from './Footer.module.css'




class Footer extends React.Component {
    render() {
        return (
            <div>
                <Container t={2} className={styles.pad}>
                    <Grid t={2} container spacing={12} justify='center'>
                        <Typography t={2}>
                            <Link underline='none' className={styles.white} href="mailto:sebastian@for-each.de" color='inherit'>
                                Sebastian Szuba - 2020 <Link className={styles.white} href="https://www.instagram.com/foreachbs"><FontAwesomeIcon icon={faInstagram} /></Link> <Link className={styles.white} href="https://www.facebook.com/forEachbs"><FontAwesomeIcon icon={faFacebook} /></Link>
                            </Link>
                        </Typography>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default Footer
