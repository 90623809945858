import React from 'react';
import Cards from './components/Cards/Cards';
import styles from './App.module.css';
import Footer from './components/Footer/Footer';

class App extends React.Component {
  render() {
    return (
      <div className={styles.body}>
        <Cards />
        <Footer />
      </div>
    );
  }
}

export default App;
